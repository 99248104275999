// new for 2018 Creative Refresh
// styles for themes/mac_base/template_api/products/product_price_v1
@import '../../../../scss/theme-bootstrap';

.product-price {
  &--sale {
    @if $cr21_spp == false {
      color: $color--red;
    }
  }
  &--discounted {
    text-decoration: line-through;
    margin-right: 4px;
    @if $cr21_spp == true {
      font-family: $ano_regular-font;
      color: $color-shade-name;
    }
  }
}
